import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export default function Dashboard() {
    const [isImpressumOpen, setIsImpressumOpen] = useState(false);
    const [isHaftungsausschlussOpen, setIsHaftungsausschlussOpen] = useState(false);

    function openImpressumModal() {
        setIsImpressumOpen(true);
    }

    function closeImpressumModal() {
        setIsImpressumOpen(false);
    }

    function openHaftungsausschlussModal() {
        setIsHaftungsausschlussOpen(true);
    }

    function closeHaftungsausschlussModal() {
        setIsHaftungsausschlussOpen(false);
    }

    return (
        <main className="flex min-h-screen flex-col items-center justify-center p-4 bg-gradient-to-br from-blue-400 to-blue-800">
            <div className="w-full max-w-3xl p-8 bg-white rounded-lg shadow-lg">
                <div className="flex flex-col items-center mb-8">
                    <img
                        src="https://eu2.contabostorage.com/e345458b32d14296b9cca425e4f39715:ersparnis-expert/ersparnis-expert-logo.png" // Ändere dies zu deinem Profilbild
                        alt="Profile Picture"
                        width={120}
                        height={120}
                        className="rounded-full border-4 border-blue-400"
                    />
                    <h1 className="mt-4 text-3xl font-bold text-gray-800">Ersparnis Expert</h1>
                    <p className="text-gray-600">Ich helfe dir, deine Finanzen zu optimieren.</p>
                </div>
                <div className="w-full flex flex-col space-y-4">
                    <a
                        href="https://a.check24.net/misc/click.php?pid=752421&aid=18&deep=c24bank&cat=14"
                        className="w-full p-4 text-center text-white bg-indigo-500 rounded-lg hover:bg-indigo-600 transition duration-300 transform hover:scale-105"
                    >
                        Kostenloses C24 Konto
                    </a>
                </div>
            </div>
            <div className="absolute bottom-2 text-zinc-200 space-x-4">
                <button onClick={openImpressumModal}>Impressum</button>
                <button onClick={openHaftungsausschlussModal}>Haftungsausschluss</button>
            </div>

            <Transition appear show={isImpressumOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeImpressumModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Impressum
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm font-bold text-gray-500">
                                            Angaben gemäß § 5 TMG
                                        </p>
                                        <div className="mt-5 text-gray-500">
                                            <span>Veysel Safak</span><br/>
                                            <span>Neusserstraße 501b</span><br/>
                                            <span>41065 Mönchengladbach</span><br/>
                                        </div>
                                        <div className="mt-5 text-gray-500 mb-6">
                                            <span>Telefon: +4915738107889</span><br/>
                                            <span>E-Mail: info@ersparnis-expert.net</span>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                            onClick={closeImpressumModal}
                                        >
                                            Schließen
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            <Transition appear show={isHaftungsausschlussOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeHaftungsausschlussModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Haftungsausschluss
                                    </Dialog.Title>
                                    <div className="mt-2 text-gray-500">
                                        <p className="text-sm">
                                            <strong>Haftung für Inhalte</strong><br/>
                                            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br/><br/>

                                            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br/><br/>

                                            <strong>Haftung für Links</strong><br/>
                                            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br/><br/>

                                            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br/><br/>

                                            <strong>Urheberrecht</strong><br/>
                                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br/><br/>

                                            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br/><br/>

                                            <strong>Streitschlichtung</strong><br/>
                                            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" className="text-blue-600 hover:underline">https://ec.europa.eu/consumers/odr</a>. Unsere E-Mail-Adresse finden Sie oben im Impressum.<br/><br/>

                                            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                            onClick={closeHaftungsausschlussModal}
                                        >
                                            Schließen
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </main>
    );
}
